<template>
  <div class="mv-content">
    <div>
      <div
        class="bk-voip-select"
        v-if="!showFullScreen">
        <!--头部-->
        <div class="bkcontent">
          <book-mark-device
            :bookmarkChecked="bookmarkChecked"
            :currentSplitIndex="splitIndex"></book-mark-device>
        </div>
        <div class="sortSelect left">
          <el-select
            class="pawan"
            placeholder="Select"
            v-model="sort"
            @change="changeSort">
            <el-option
              v-for="item in sortList"
              :key="item.value"
              :label="$t(`lang.${item.label}`)"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div
          id="voipBox"
          class="voipBox voipBoxMedia"
          v-if="isInit">
          <div class="voipCall">
            <i
              class="iconfont openCall"
              @click="openCallDailog"
              v-if="voipWitchIcon == 0">&#xe643;</i>
            <i
              class="el-icon-d-arrow-left numDis"
              @click="closeVoipDom"
              v-if="voipWitchIcon == 1"></i>
            <i
              class="el-icon-d-arrow-right numDis red"
              @click="closeVoipDom"
              v-if="voipWitchIcon == 2"></i>
            <p
              id="callingNum"
              class="calling-num"
              v-if="voipWitchIcon == 1 || voipWitchIcon == 2">
              {{voipDomArr.length}}</p>
          </div>
          <div class="currentVoip">
            <div class="voipBox left">
              <div
                v-for="item in voipDomArr"
                :key="item.peerId">
                <VoipItem
                  :currentRInfo="item"
                  :key="item.peerId">
                </VoipItem>
              </div>
            </div>
            <div class="control-big-box right">
              <div class="control-call-box">
                <i
                  class="iconfont modifyVoipMember"
                  @click="modifyVoipMember">&#xe6ea;</i>
                <i
                  class="iconfont stopAllViop"
                  @click.stop="stopAllVoip">&#xe644;</i>
              </div>
            </div>
          </div>
        </div>
        <div class="splitScreen">
          <div class="showInfo">
            {{$t('lang.showInfo')}}
            <el-switch
              v-model="showInfo"
              active-color="#13ce66"
              @change="changeShowInfo()">
            </el-switch>
          </div>
          <div class="splitScreenNum">
            <!--分屏数量选择-->
            <i
              class="iconfont split4"
              :class="{'splitActive':splitIndex == 4}"
              @click="changeSplit(4)">&#xe684;</i>
            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 6}"
              @click="changeSplit(6)">&#xe631;</i>
            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 9}"
              @click="changeSplit(9)">&#xe62f;</i>
            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 16}"
              @click="changeSplit(16)">&#xe632;</i>
            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 25}"
              @click="changeSplit(25)">&#xe633;</i>
            <i
              class="iconfont fullScreen splitActive"
              @click="fullScreen(true)">&#xe658;</i>
          </div>
          <div
            class="left btn_green"
            v-if="isShowGoToNewMultiview">
            <a
              href="javascript:void(0)"
              :title="$t('lang.tomultiviewplus')"
              @click="goToNewMultiview">
              {{$t('lang.tomultiviewplus')}} </a>
          </div>
        </div>
      </div>
      <div
        class="fullScreenBorder"
        v-if="showFullScreen">
        <div
          id="voipBox"
          v-if="isInit">
          <div class="voipCall">
            <i
              class="iconfont openCall"
              @click="openCallDailog"
              v-if="voipWitchIcon == 0">&#xe643;</i>
            <i
              class="el-icon-d-arrow-left numDis"
              @click="closeVoipDom"
              v-if="voipWitchIcon == 1"></i>
            <i
              class="el-icon-d-arrow-right numDis red"
              @click="closeVoipDom"
              v-if="voipWitchIcon == 2"></i>
            <p
              id="callingNum"
              class="calling-num"
              v-if="voipWitchIcon == 1 || voipWitchIcon == 2">
              {{voipDomArr.length}}</p>
          </div>
          <div class="currentVoip">
            <div class="voipBox left">
              <div
                v-for="item in voipDomArr"
                :key="item.peerId">
                <VoipItem
                  :currentRInfo="item"
                  :key="item.peerId">
                </VoipItem>
              </div>
            </div>
            <div class="control-big-box right">
              <div class="control-call-box">
                <i
                  class="iconfont modifyVoipMember"
                  @click="modifyVoipMember">&#xe6ea;</i>
                <i
                  class="iconfont stopAllViop"
                  @click.stop="stopAllVoip">&#xe644;</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <i
        class="iconfont fullScreen splitActive fullClose"
        @click="fullScreen(false)"
        v-if="showFullScreen">&#xe659;</i>
      <div
        class="receiverLists"
        :class="{'view4-padding':splitIndex ==4, 'view6-padding':splitIndex ==6,'view9-padding':splitIndex ==9,'view16-padding':splitIndex ==16,'view25-padding':splitIndex ==25}"
        v-if="isInit">
        <div
          class="left splitBorder"
          v-for="item in splitDataArr"
          :class="{'videosize-4':splitIndex ==4, 'videosize-6':splitIndex ==6,'videosize-9':splitIndex ==9,'videosize-16':splitIndex ==16,'videosize-25':splitIndex ==25}"
          :key="item.peerId">
          <receiver-list
            :splitItemInfo="item"
            :splitSocketInfo="allRSocketInfo[item.peerId]"
            :splitIndex="splitIndex"
            :sendSplitIndex="splitIndex"
            :showInfoRe="showInfo"
            :isFull="showFullScreen"
            :mataData="mataData"></receiver-list>
        </div>
      </div>
      <SelectDevice
        v-if="selectVoipDevice"
        :selectDevice="selectVoipDevice"
        :canVoipData="canVoipDom"
        :currentVoipData="voipDomArr"
        :allSocketData="allRSocketInfo"
        class="selectDevice">
      </SelectDevice>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import BookMarkDevice from './BookMarkDevice'
import ReceiverList from './ReceiverList'
import SelectDevice from './SelectDevice'
import VoipItem from './VoipItem'
import { multiviewAnalyze } from '@/assets/js/googleAnalyze/multiview.js'
export default {
  components: {
    ReceiverList,
    SelectDevice,
    VoipItem,
    BookMarkDevice
  },
  props: {},
  data () {
    return {
      defaultBookmark: { // 默认bookmark选中的值l
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      showInfo: false, // showInfo开关
      splitIndex: 0,
      splitDataArr: [], // R列表
      peerIds: [], // 分屏Rid
      initData: [], // 初始化数据
      allRSocketInfo: [], // 组装后的所有websocket中的数据
      selectVoipDevice: false, // 控制选择设备进行通话
      voipWitchIcon: 0, // 显示哪个按钮
      voipDom: false, // voip面板
      voipDomArr: [], // 当前在播voip的数据
      voipDomArrCopy: [], // 当前在播voip的数据
      canVoipDom: [], // 当前可播voip的数据
      selectDeviceData: [], // 从selectDevice组件获取的数据
      liveDevicePlatform: [10, 11, 12, 13, 14], // 支持voip的source的平台号
      rvfPackOnArr: [], // 控制errorMsg的显示或隐藏
      showFullScreen: false, // 是否显示全屏
      bookmarkChecked: [], // 当前选中的R的id列表
      canVoipPlay: false, // voip按钮是否可以点击
      isInit: false, // 是否初始化完成
      initCurrentInfoSocket: true,
      isClick: false, // 是否点击了下拉
      mataData: [], // mataData信息
      showNewMv: false,
      iframeUrl: window.location.protocol + '//' + JSON.parse(window.localStorage.newCCInitConfig).urlInfo.mySourceUrl + '/tvugm/index.html',
      loading: false,
      isShowGoToNewMultiview: this.$store.state.common.baseUrl.enableNewMultiView,
      sort: 'Name',
      sortList: [{
        value: 'Time',
        label: 'byChosen'
      }, {
        value: 'Name',
        label: 'byName'
      }, {
        value: 'Status',
        label: 'byStatus'
      }]
    }
  },
  created () {
    this.getSort()
    Bus.$on('selectDeviceEmit', (val) => { // 选择设备弹窗是否关闭
      this.selectVoipDevice = val
      let event = ''
      if (val) {
        event = 'Open'
      } else {
        event = 'Close'
      }
      multiviewAnalyze(`multiviewSelectVoipDevice${event}`, this.$route.path)
    })
    Bus.$on('voipWitchIcon', (val) => { // 哪个voip图标显示
      this.voipWitchIcon = val
      if (val == 1) {
        this.voipDom = true
        $('.currentVoip').addClass('currentVoipLength')
      }
    })
    Bus.$on('splitChangeEmit', (val) => { // 删除R列表/bookmark操作的监听
      const peerId = val.operatePeerId.slice(0, 16)
      this.resolveReceiverData(val.operate, peerId, val)
    })

    Bus.$on('voipCheckList', (val) => { // 获取选择voip对象
      this.resolveVoipData(val)
    })

    window.localStorage.setItem('mvIsStartVoip', 'true')// 初始化voip的显示
    this.States.mvVoipStream = []
    const that = this
    // 点击别处--下拉列表消失
    document.addEventListener('click', function (e) {
      e = e || window.event
      var tar = e.srcElement || e.target
      var tarObj = $(tar).parent()
      if (!tarObj.hasClass('bookMarkPart') &&
        !tarObj.hasClass('mvBookMark') &&
        !tarObj.hasClass('mvBookMarkC') &&
        !tarObj.hasClass('dataSpan') &&
        !tarObj.hasClass('narrow') &&
        !tarObj.hasClass('selectBox') &&
        !tarObj.hasClass('bookList') &&
        !tarObj.hasClass('ReceiverData') &&
        !tarObj.hasClass('dropDefault') &&
        !tarObj.hasClass('selectTitle') &&
        !tarObj.hasClass('bkcontent')
      ) {
        that.isClick = false
      }
    }, true)

    window.getSendIframe = () => {
      const newMvInfo = {
        serviceUrl: JSON.parse(window.localStorage.newCCInitConfig).urlInfo.mySourceUrl
      }
      return newMvInfo
    }

    window.getNewMvInfo = (data) => { // 获取iframe中上传来的信息
      if (data.type == 'createSuccess') {
        this.loading = false
      } else if (data.type == 'closeIframe') {
        this.showNewMv = !this.showNewMv
      } else if (data.type == 'closeHeader') {
        $('#headerBox').slideUp()
        $('#pro_nav_icon_bg').hide()
        $('.el-main').addClass('heightMain')
      } else if (data.type == 'openHeader') {
        $('#headerBox').slideDown()
        $('#pro_nav_icon_bg').show()
        $('.el-main').removeClass('heightMain')
      } else if (data.type == 'closeLoading') {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState({
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    }),
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc,
      mvVoipStream: state => state.mvVoipStream
    })
  },
  watch: {
    splitDataArr (val, oldVal) { // 监听receiverlist的变化 用以停止currentRInfo 的websocket 防止数据获取不到
      let peerIdsLenght = 0
      let onLineOrLive = 0
      val.map(item => {
        if (item.isVideoDom) peerIdsLenght++
        if (item.status && item.status != '0') onLineOrLive += 1
      })
      if (peerIdsLenght > 0 && onLineOrLive >= 1) { // 开启websocket
        if (this.initCurrentInfoSocket) {
          this.createNewRWebsocket()
          this.initCurrentInfoSocket = false
        }
      } else { // 关闭websocket
        this.initCurrentInfoSocket = true
        this.stopNewRWebsocket()
        this.stopMataDataWebsocket()
      }

      if (peerIdsLenght > 0) {
        this.sendMataDataWebSocketOnce()
      } else {
        this.stopMataDataWebsocket()
      }
    },
    // 当从Anywhere端关闭VOIP时，需要修改正在进行VOIP的设备数量
    mvVoipStream (val, oldVal) {
      if (val && val.length && this.voipDomArr.length) {
        const arr = []
        val.forEach(m => {
          const item = this.voipDomArr.filter(n => n.peerId == m.peerId)
          if (item.length) arr.push(item[0])
        })
        this.resolveVoipData(arr)
      } else {
        if ((!val || !val.length) && this.voipDomArr && this.voipDomArr.length) {
          this.resolveVoipData([])
        }
      }
    }
  },
  methods: {
    ...mapMutations('common', [
      'CURRENT_RINFO',
      'DEVICELIST_VOLUMECHARTDATA',
      'DEVICELIST_SLOTINFODATA',
      'DEVICELIST_RECEIVERDATADATA',
      'DEVICELIST_CURRENTRINFODATA',
      'DEVICELIST_SETINFODATA'
    ]),
    showBookmark () {
      this.isClick = !this.isClick
      let event = ''
      if (this.isClick) {
        event = 'Open'
        this.$nextTick(() => {
          $('#secondBook').addClass('hide')
          $('#thirdBook').addClass('hide')
          $('#fourthBook').addClass('hide')
          $('#fifthBook').addClass('hide')
          $('.mvBookMark').css('width', (5 - $('.bookMarkPart.hide').length) * 200)
        })
      } else {
        event = 'Close'
      }
      multiviewAnalyze(`multiviewBookmark${event}`, this.$route.path)
    },
    resolveReceiverData (type, peerId, val) { // 处理bookmark添加/删除设备
      if (type == 'addReceiver') {
        let currentVideoDomLength = 0
        this.splitDataArr.map((item) => {
          if (item.isVideoDom) currentVideoDomLength++
        })

        let addVideoDom = {}
        addVideoDom = val.rInterFaceData
        if (addVideoDom) {
          addVideoDom = Object.assign(addVideoDom, { isVideoDom: true })
        } else {
          addVideoDom = Object.assign({}, { isVideoDom: true })
        }
        addVideoDom.bookmarkId = val.bookmarkId
        addVideoDom.bookmarkName = val.bookmarkName
        this.splitDataArr.splice(currentVideoDomLength, 0, addVideoDom) // 添加一个video dom
        this.splitDataArr.splice(currentVideoDomLength + 1, 1) // 删除下面一项video dom
      } else if (type == 'removeReceiver') {
        let deleteIndex = null
        this.splitDataArr.map((item, index) => {
          const deletePeerId = item.peerId
          if (peerId == deletePeerId) {
            deleteIndex = index
          }
        })
        if (deleteIndex || deleteIndex == 0) {
          this.splitDataArr.splice(deleteIndex, 1) // 删除当前video dom
          let newData = []
          this.splitDataArr.map(item => { // 重新排列数据
            if (item.isVideoDom) {
              newData.push(item)
            }
          })
          for (let i = newData.length; i < this.splitIndex; i++) { // 将其余的空间 使用占位符补上
            const videoStation = { // 占位符,没有视频的占地空间
              isVideoDom: false
            }
            newData.push(videoStation)
          }
          this.splitDataArr = newData
        }
      }
      this.bookmarkChecked = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          this.bookmarkChecked.push(item.peerId)
        }
      })
      multiviewAnalyze(`multiview${type}Receiver`, this.$route.path)
    },
    getSort () {
      const params = {
        pn: 'mutilviewSort'
      }
      this.axios.post('/ccp/tvucc-user/userDevice/listPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            let content = null
            if (res.data.result.length && res.data.result[0].content) content = JSON.parse(res.data.result[0].content)
            this.sort = content ? content.sort : 'Name'
            this.getBehaviour()
          }
        }).catch(() => {})
    },
    changeSort () {
      const params = {
        pn: 'mutilviewSort',
        content: JSON.stringify({
          sort: this.sort
        })
      }
      this.axios.post('/ccp/tvucc-user/userDevice/setPreference', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (res.data.errorCode == '0x0') {
          this.getBehaviour()
        }
      }).catch(() => {})
    },
    getBehaviour () { // 初始化数据
      this.axios.get(`/ccp/tvucc-user/userDevice/queryUserBehaviorForMv?sort=${this.sort}`).then(res => {
        if (res.data.errorCode == '0x0') {
          // 默认没有数据或没有在线的R时，websocket相当于没有初始化
          const result = res.data.result
          if (result.device4MultiView && result.device4MultiView.length) {
            let flag = false
            result.device4MultiView.forEach(item => {
              if (item.status != 0) flag = true
            })
            if (flag) this.initCurrentInfoSocket = false// 监听peerId停止
          }
          this.resolveInitData(res)
          this.isInit = true
          this.sendWebsocket()
          this.createNewRWebsocket()
        } else {
          console.log('interface Error... ' + res.data) // 打印,看下复制网页是什么问题
        }
      }).catch(() => {})
    },
    resolveInitData (res) { // 处理初始化的数据
      if (res.data.result) {
        const splitArrData = res.data.result.device4MultiView
        const splitNum = res.data.result.splitScreenNum
        if (splitNum && splitNum != null && JSON.parse(splitNum)) { // 分屏信息处理
          const number = JSON.parse(splitNum).thisObjId
          if (number == 'set4SplitScreen') {
            this.splitIndex = 4
          } else if (number == 'set6SplitScreen') {
            this.splitIndex = 6
          } else if (number == 'set9SplitScreen') {
            this.splitIndex = 9
          } else if (number == 'set16SplitScreen') {
            this.splitIndex = 16
          } else if (number == 'set25SplitScreen') {
            this.splitIndex = 25
          } else {
            this.splitIndex = 25
          }
        } else { // 处理数据异常
          if (splitArrData && splitArrData != null && splitArrData.length > 0) {
            const receiverLength = splitArrData.length
            if (receiverLength > 0) {
              if (receiverLength <= 4) {
                this.splitIndex = 4
              } else if (receiverLength > 4 <= 6) {
                this.splitIndex = 6
              } else if (receiverLength > 6 <= 9) {
                this.splitIndex = 9
              } else if (receiverLength > 9 <= 16) {
                this.splitIndex = 16
              } else if (receiverLength > 16 <= 25) {
                this.splitIndex = 25
              } else {
                this.splitIndex = 25
              }
            } else {
              this.splitIndex = 4
            }
          } else {
            this.splitIndex = 25
          }
        }
        if (splitArrData != '' && splitArrData != 'undefined' && splitArrData != null && splitArrData.length > 0) { // 如果有数据, receiverist数据处理
          const splitJsonData = splitArrData
          this.splitDataArr = []
          splitJsonData.map(item => {
            item.isVideoDom = true// 将有视频的添上标志
            this.splitDataArr.push(item)
            if (!this.bookmarkChecked.includes(item.peerId)) this.bookmarkChecked.push(item.peerId)
          })
          const splitJsonDataL = splitJsonData.length
          for (let i = splitJsonDataL; i < this.splitIndex; i++) { // 将其余的空间 使用占位符补上
            const videoStation = { // 占位符,没有视频的占地空间
              isVideoDom: false
            }
            this.splitDataArr.push(videoStation)
          }
        } else { // 如果没有数据 将所有空间使用占位符补上
          for (let i = 0; i < this.splitIndex; i++) {
            const videoStation = { // 占位符,没有视频的占地空间
              isVideoDom: false
            }
            this.splitDataArr[i] = videoStation
          }
        }
        let assemblyData = []
        this.splitDataArr.map(item => {
          assemblyData[item.peerId] = item
        })
        this.allRSocketInfo = assemblyData // 初始化 将接口数据当做websocket数据

        if (res.data.result.showInfo) {
          const showInfo = res.data.result.showInfo
          this.showInfo = JSON.parse(showInfo).showInfo
        }
      } else {
        this.splitIndex = 25
      }
    },
    changeShowInfo () { // show info 函数的控制
      let event = ''
      if (this.showInfo) { // showInfo打开control module显示
        Bus.$emit('controlModuleChange', false)
        Bus.$emit('controlModuleChangeVideoC', false)
        event = 'Open'
      } else {
        Bus.$emit('controlModuleChange', true)
        Bus.$emit('controlModuleChangeVideoC', true)
        event = 'Close'
      }
      const sendMsg = {
        pn: 'mv',
        type: 'showInfo',
        content: '',
        remark: 'showInfo'
      }
      const content = {
        showInfo: this.showInfo
      }
      sendMsg.content = content
      this.axios.get('/ccp/tvucc-user/userDevice/saveUserBehavior', { params: sendMsg }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (this.showInfo) {
            console.log('Show info is open...')
          } else {
            console.log('Show info is close...')
          }
        }
      }).catch(() => {})
      multiviewAnalyze(`multiviewShowInfo${event}`, this.$route.path)
    },
    handleResize () {
      const splitBorder = $('.left.splitBorder').width()
      $('.left.splitBorder').css('height', splitBorder * 212 / 363)
    },
    changeSplit (index) { // 切换分屏
      let currentVideoLength = 0
      this.splitDataArr.map(item => { // 判断当前有几个video 到时候接口做好了 可以在data中声明一个变量,记录当前添加的video数量
        if (item.isVideoDom) {
          currentVideoLength++
        }
      })
      if (index < currentVideoLength) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.cannotSwitchSplitScreenMode')
        })
        return false
      }
      this.splitDataArr.splice(currentVideoLength, this.splitIndex - currentVideoLength)// 将不是video dom的去掉 省去判断插入几个空格子的问题
      for (let i = currentVideoLength; i < index; i++) { // 将其余的空间 使用占位符补上
        const videoStation = { // 占位符,没有视频的占地空间
          isVideoDom: false
        }
        this.splitDataArr[i] = videoStation
      }
      this.splitIndex = index // 切换分屏
      const param = {
        pn: 'mv',
        remark: 'splitScreen',
        type: 'splitScreen'
      }
      let content = {}
      if (this.splitIndex) {
        content.thisObjId = `set${this.splitIndex}SplitScreen`
        content.multi_videoBigBox = `view${this.splitIndex}-padding`
        content.singleVideoBox = `video${this.splitIndex}-size`
      }
      param.content = content
      this.axios.get('/ccp/tvucc-user/userDevice/saveUserBehavior', { params: param }).then(res => {
        if (res.data.errorCode == '0x0') {}
      }).catch(() => {})
      this.$nextTick(() => {
        this.handleResize()
      })
      const event = this.splitIndex
      multiviewAnalyze(`multiviewSpliteScreen${event}`, this.$route.path)
    },
    fullScreen (val) { // 全屏
      let event = ''
      if (val) {
        $('#headerBox').slideUp()
        $('.el-main.scrollbar').css('height', '100%')
        $('.bk-voip-select').slideUp(() => {
          $('.mv-content').css('paddingTop', '20px')
          $('#pro_nav_icon_bg').hide()
          this.showFullScreen = true
          this.$nextTick(() => {
            $('#voipBox').css('width', this.voipDomArr.length + 120)
          })
        })
        event = 'Open'
      } else {
        $('.el-main.scrollbar').css('height', 'calc(100% - 64px)')
        this.showFullScreen = false
        $('#headerBox').slideDown()
        $('#pro_nav_icon_bg').show()
        $('.mv-content').css('paddingTop', '0')
        $('.bk-voip-select').slideDown()
        event = 'Close'
        this.$nextTick(() => {
          $('#voipBox').css('width', 820)
          $('.bkcontent').css('width', 200)
        })
      }
      $('.currentVoip').removeClass('currentVoipLength')
      if (this.voipWitchIcon != 0) {
        this.voipWitchIcon = 1
      }
      this.voipDom = false
      multiviewAnalyze(`multiviewFullScreen${event}`, this.$route.path)
    },

    goToNewMultiview () {
      this.$router.push({ name: 'MultiViewPlus' })
      multiviewAnalyze('multiviewGoToNewMultiview', this.$route.path)
    },
    modifyVoipMember () { // 编辑通话对象
      this.openCallDailog()
    },
    openCallDailog () {
      /*
      * 初始化的时候 点击通话按钮
      * 打开选择设备去通话的弹窗&&获取可以通话的对象
      */

      if (!this.canVoipPlay) { // 如果websocket数据还没有返回 不能点击voip
        return
      }
      this.canVoipDom = []// 获取正在live的R
      this.splitDataArr.map(item => {
        if (item.isVideoDom) { // 当前格子是否含有R设备
          const currentRId = item.peerId
          const currentRInfo = this.allRSocketInfo[currentRId]
          if (currentRInfo) {
            let currentRInfoSVersion = false
            if (currentRInfo.livePeerVersion && currentRInfo.livePeerVersion != null) {
              let livePeerVersion = currentRInfo.livePeerVersion.slice(0, 3)// 因为接口中没有这个字段 因此使用websocket中的
              livePeerVersion = (livePeerVersion - 0) * 10
              if (livePeerVersion >= 60) {
                currentRInfoSVersion = true
              }
            }
            const plateForm = this.liveDevicePlatform.includes(Number(currentRInfo.liveDevicePlatform))
            if (currentRInfo.status == 2 && currentRInfo.livePeerType && currentRInfo.livePeerType.toLowerCase() == 't' && currentRInfoSVersion || plateForm) { // 将状态为2,当前Live的source类型为T,T版本大于60,或者source平台在[10, 11, 12, 13, 14]内方可进行通话
              // 这地方目前还差一个livePeerVersion判断
              this.canVoipDom.push(item)
            }
          }
        }
      })
      if (this.canVoipDom.length <= 0) { // 没有可以去voip通话的设备
        /*
        * 将状态为2,当前Live的source类型为T,T版本大于60,或者source平台在[10, 11, 12, 13, 14]内方可进行通话
        * 只有TVU背包和手机能够支持语音通话
        */
        this.$message({
          type: 'warning',
          message: this.$t('lang.selectMonitorSelectCall')
        })
        return false
      }
      this.selectVoipDevice = true// 开启选择voip对象的弹框
    },
    resolveVoipData (val) { // 处理选择完voip对象的数据处理函数
      this.selectDeviceData = val
      const currentVoipLength = this.voipDomArr.length
      if (currentVoipLength == 0) {
        this.voipDomArr = val
      }
      const voipStream = this.States.mvVoipStream
      this.voipDomArr.map(item => { // 找出取消勾选的
        let isIn = false
        const oldPeerId = item.peerId
        this.selectDeviceData.map(itm => {
          if (oldPeerId == itm.peerId) {
            isIn = true
          }
        })
        if (!isIn) {
          if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
            const voipId = this.allRSocketInfo[item.peerId].livePeerId.toUpperCase()
            this.Webrtc.stopCall('0x' + voipId)
            if (voipStream.length > 0) {
              voipStream.map((itm, idx) => {
                if (item.peerId == itm.peerId) {
                  itm.status = false
                  voipStream.splice(idx, 1)
                }
              })
              this.States.mvVoipStream = voipStream
            }
          }
        }
        if (voipStream.length > 0) {
          voipStream.map(itemStr => { // 将已经进行过voip的 而且没有取消voip的流赋值给对应的audio标签
            const currentVoip = document.getElementById(itemStr.peerId) // 有指定的dom
            if (currentVoip && itemStr.stream) {
              currentVoip.srcObject = itemStr.stream
              itemStr.status = true
              this.$store.state.common.mvVoipStream = voipStream
            }
          })
        }
      })
      this.voipDomArr = this.selectDeviceData // 将现在勾选的赋值
      if (this.voipDomArr.length == 0) {
        $('.voipBox.left').addClass('width0')
        if (!val || !val.length) {
          this.States.mvVoipStream = []
          this.stopAllReset()
          Bus.$emit('voipCloseAll', true)
        } else {
          this.stopAllVoip()
        }
      } else {
        $('.voipBox.left').removeClass('width0')
        const voipDomWidth = this.voipDomArr.length * 104 + 'px'
        $('.voipBox.left').css('width', voipDomWidth)
      }
      this.$nextTick(() => {
        $('#voipBox').css('width', this.voipDomArr.length * 104 + 190)
      })
    },
    stopAllVoip () { // 取消全部通话
      this.$confirm(this.$t('lang.interruptAllCall'), '', {
        confirmButtonText: this.$t('lang.interruptAll'),
        cancelButtonText: this.$t('lang.cancel'),
        center: true,
        callback: action => {
          if (`${action}` == 'confirm') {
            this.voipDomArr.map(item => {
              if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
                const voipId = this.allRSocketInfo[item.peerId].livePeerId.toUpperCase()
                this.Webrtc.stopCall('0x' + voipId)
              }
              multiviewAnalyze('multiviewAllVoipClose', this.$route.path)
            })
            this.States.mvVoipStream = []
            this.stopAllReset()
            Bus.$emit('voipCloseAll', true)
          }
        }
      }
      )
    },
    stopAllReset () { // 停止全部通话的时候的数据初始化
      this.voipWitchIcon = 0
      $('.currentVoip').removeClass('currentVoipLength')
      this.voipDomArr = []
      window.localStorage.setItem('mvIsStartVoip', 'true')// 初始化voip的显示
    },
    closeVoipDom () { // 关闭voip面板
      this.voipDom = !this.voipDom
      if (this.voipDom) {
        this.voipWitchIcon = 1
        $('.currentVoip').addClass('currentVoipLength')
      } else {
        this.voipWitchIcon = 2
        $('.currentVoip').removeClass('currentVoipLength')
      }
      if (this.voipDomArr.length == 0) {
        $('.voipBox.left').addClass('width0')
        this.$nextTick(() => {
          $('#voipBox').css('width', this.voipDomArr.length * 104 + 190)
        })
      } else {
        $('.voipBox.left').removeClass('width0')
        const voipDomWidth = this.voipDomArr.length * 104 + 'px'
        $('.voipBox.left').css('width', voipDomWidth)
        this.$nextTick(() => {
          $('#voipBox').css('width', this.voipDomArr.length * 104 + 190)
        })
      }
    },
    sendWebsocket (data) { // 更新设备状态
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_Mv_R') // 停止websocket
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_Mv_R',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_Mv_R',
        success: res => {
          // 更新websocket参数
          const data = res.result
          let assemblyData = []
          data.map(item => {
            assemblyData[item.peerId] = item
          })
          this.canVoipPlay = true
          this.allRSocketInfo = assemblyData
          let newParam = []
          this.splitDataArr.map(item => {
            if (item.isVideoDom) {
              newParam.push(item.peerId)
            }
          })
          this.peerIds = newParam
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_Mv_R',
            data: { peerIds: this.peerIds.join(',') }
          }

          this.pageRequest.sendMsgs.taskInfo_Mv_R.message = taskInfoMsg
        }
      })
    },
    createNewRWebsocket () { // 创建currentRInfo websocket
      this.$createWebsocket.pageRWebsocket('stop', ['receiverData'])
      this.$createWebsocket.pageRWebsocket('stop', ['currentRInfo'])
      this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000)
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000)
    },
    stopNewRWebsocket () { // 停止currentRInfo websocket
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'receiverData', 'currentRInfo', 'setInfo']) // 停止websocket
      }
    },
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.peerIds.join(',')
        },
        time: time || 1000,
        key: typeVal || type,
        success: res => {
          const pageRListInfo = this.pageRWebsocket.sendMsgs[type.charAt(0).toLowerCase() + type.slice(1)]
          if (pageRListInfo && !this.selectRIng) {
            let peerIdsStr = []
            this.splitDataArr.map(item => {
              if (item.isVideoDom) {
                peerIdsStr.push(item.peerId)
              }
            })
            this.peerIds = peerIdsStr
            pageRListInfo.message = {
              categoryId: categoryId,
              operationType: operationType,
              rid: this.peerIds.join(',')
            }
          }
          this.updateDeviceDetailData(`deviceList${type}Data`, `DEVICELIST_${type.toUpperCase()}DATA`, res)
        }
      })
    },
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      this.States[deviceData].map((v, i) => {
        if (this.peerIds.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },

    sendMataDataWebsocket () {
      let sendParam = []
      if (this.splitDataArr) {
        this.splitDataArr.map(item => {
          if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
            sendParam.push(item.livePeerId)
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getMetadataList') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest.send({
        message: {
          type: 'getMetadataList',
          module: 'getMetadataList',
          data: { peerIds: sendParam.join(',') }
        },
        time: 30000,
        key: 'getMetadataList',
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).length > 0) {
                result = JSON.parse(data.result)
                this.mataData = result
              }
            }
          }

          let newParam = []
          if (this.splitDataArr) {
            this.splitDataArr.map(item => {
              if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
                newParam.push(item.livePeerId)
              }
            })
          }
          newParam = [...new Set(newParam)]
          const taskInfoMsg = {
            type: 'getMetadataList',
            module: 'getMetadataList',
            data: { peerIds: newParam.join(',') }
          }
          this.pageRequest.sendMsgs.getMetadataList.message = taskInfoMsg
        }
      })
    },
    sendMataDataWebSocketOnce () { // 开始发送一次 以后没60s发送一次
      let sendParam = []
      if (this.splitDataArr) {
        this.splitDataArr.map(item => {
          if (item.status == 2) {
            sendParam.push(item.livePeerId)
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getMetadataList')
      }
      this.pageRequest.send({
        message: {
          type: 'getMetadataList',
          module: 'getMetadataList',
          data: { peerIds: sendParam.join(',') }
        },
        time: 0,
        key: 'getMetadataList',
        isOnce: true,
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).length > 0) {
                result = JSON.parse(data.result)
                this.mataData = result
              }
            }
          }

          if (this.$createWebsocket.pageRequest) {
            this.$createWebsocket.pageRequest('stop', 'getMetadataList')
          }
          this.sendMataDataWebsocket()
        }
      })
    },
    stopMataDataWebsocket () { // 停止currentRInfo websocket
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', 'getMetadataList') // 停止websocket
      }
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_Mv_R') // 停止websocket
    }
    this.stopNewRWebsocket()
    this.stopMataDataWebsocket()
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/mvAdaptive/index.less'; //响应式文件
.btn_green {
  height: 36px;
  width: 140px;
  line-height: 36px;
  text-align: center;
  background: #444;
  border-radius: 4px;
  color: #909090;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

#app {
  background: #252525;
}

.statusBox {
  float: left;
  margin: 8px 20px 0;
  .allStatus {
    margin-right: 10px;
  }
  .statusName {
    margin-right: 10px;
  }
  .statusCheckBox {
    display: inline-block;
    .statusCheck {
      margin-right: 20px;
    }
  }
}

.selectDevice {
  z-index: 19999 !important;
}

.sc::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.sc::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

.sc::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: none;
}

#app::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

#app::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

#app::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: none;
}

.fullClose {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.36rem;
  z-index: 10000000000;
}

.voipBoxBottom {
  width: 72px;
}

.mv-content {
  width: 100%;
  padding-left: 0.5%;
  height: 100% !important;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  min-width: 1024px !important;

  #voipBox {
    margin-left: 45px;
    float: left;
    position: relative;
    width: 400px;
    height: 70px;
    top: -15px;
    z-index: 1001 !important;
    overflow: hidden;

    .voipCall {
      position: absolute;
      left: 0;
      top: 17px;
      z-index: 10;

      #callingNum {
        height: 15px;
        width: 15px;
        border-radius: 7px;
        background: #fff;
        color: red;
        position: absolute;
        right: -6px;
        top: 1px;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
      }

      .openCall {
        width: 36px;
        height: 36px;
        background: #33ab4f;
        border-radius: 18px;
        font-size: 26px;
        line-height: 36px;
        text-align: center;
        z-index: 10;
      }

      .numDis {
        width: 36px;
        height: 36px;
        background: #666;
        border-radius: 18px;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        z-index: 10;
        cursor: pointer;
      }

      .red {
        background: red;
      }
    }

    .currentVoipLength {
      transform: translate(0%) !important;
      opacity: 1 !important;
    }

    .currentVoip {
      height: 70px;
      border-radius: 6px;
      position: absolute;
      left: 18px;
      top: 0;
      background: #444;
      z-index: 1;
      transform: translate(-100%);
      transition: all 0.5s;
      opacity: 0;
      overflow: hidden;

      .width0 {
        min-width: 0 !important;
        width: 0 !important;
      }

      .voipBox {
        padding: 10px 10px 0px 40px;
        background: #444;
        border-radius: 8px 0 0 8px;
        max-width: 886px;
        min-width: 147px;
        height: 70px;
        border-right: none;
        display: block;
        overflow: hidden;

        .single-calling-source {
          padding-top: 6px;
          cursor: pointer;
        }
      }

      .control-big-box {
        padding: 10px 0;
        background: #444;
        height: 70px;
        box-sizing: border-box;
        border-radius: 0px 8px 8px 0;
        /*border: 1px solid #333;*/
        border-left: none;

        .control-call-box {
          border-left: 2px solid #666;
          height: 48px;
          width: 116px;
          box-sizing: border-box;
          padding: 6px 15px;

          .modifyVoipMember {
            float: left;
            box-sizing: border-box;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            color: #fff;
            position: relative;
            background: #33ab4f;
            margin-right: 12px;
            font-size: 0.2rem;
            line-height: 36px;
          }

          .stopAllViop {
            background: red;
            float: left;
            box-sizing: border-box;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            color: #fff;
            position: relative;
            font-size: 0.2rem;
            line-height: 36px;
          }
        }
      }
    }
  }

  .fullScreenBorder {
    display: block !important;
    /*width: 100%;*/
    position: absolute;
    top: 4px;
    left: 0;
    padding: 32px 20px 0 0;
    /*z-index: 1001;*/
    overflow: hidden;

    #voipBox {
      margin-left: 0;
    }

    .fullScreen {
      position: absolute;
      right: 0;
      top: -10px;
    }

    .fullScreen {
      font-size: 0.36rem;
    }
  }

  .bk-voip-select {
    display: block !important;
    height: auto;
    width: auto;
    padding: 32px 20px 0px 20px;
    overflow-x: hidden;
    /*overflow: hidden;*/

    .bkcontent {
      /*width: 200px;*/
      height: 30px;
      float: left;
      z-index: 1000000;
      position: relative;

      .selectTitle {
        width: 200px;
        height: 30px;
        line-height: 30px;
        background: #424242;
        border: none;
        cursor: pointer;
        border-radius: 6px;
        font-size: 14px;

        .triangleDown {
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid #fff;
          margin: 12px 10px 0 0;
        }
      }

      .mvBookMarkC {
        position: absolute;
        top: 32px;
      }
    }
    .sortSelect {
      margin-left: 15px;
    }
    .splitScreen {
      width: auto;
      float: right;
      line-height: 36px;

      .showInfo {
        float: left;

        ::v-deep .el-switch__core {
          background-color: #252525;
          border: 1px solid #666666;
        }
      }

      .splitScreenNum {
        float: left;
        width: auto;
        margin-left: 0.38rem;

        .fullScreen {
          font-size: 0.3rem;
        }

        .iconfont {
          margin: 0 4px;
        }

        .split4 {
          margin-right: 6px;
        }

        i {
          font-size: 0.26rem;
          color: #666;
        }

        .splitActive {
          color: #dbdbdb;
        }
      }
    }
  }

  .receiverLists {
    /*height: 1080px;*/
    /*background: #333;*/
    box-sizing: border-box;
    margin-top: -20px;
    clear: both;

    .splitBorder {
      /*position: relative;*/
      z-index: 1;
      overflow: hidden;
    }

    .videosize-4 {
      height: 536px;
      width: 930px;
      padding: 15px;
    }

    .videosize-6 {
      width: 618px;
      height: 368px;
      padding: 10px 5px;
    }

    .videosize-9 {
      padding: 6px;
      width: 611px;
      height: 354px;
    }

    .videosize-16 {
      padding: 7px 8px;
      width: 455px;
      height: 266px;
    }

    .videosize-25 {
      padding: 5px;
      width: 363px;
      height: 212px;
    }
  }

  .view4-padding {
    padding: 5px 12px;
  }

  .view6-padding {
    padding: 5px 15px;
  }

  .view9-padding {
    padding: 9px 26px;
  }

  .view16-padding {
    padding: 8px 32px;
  }

  .view25-padding {
    padding: 10px 35px;
  }
}
</style>
