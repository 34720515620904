import { render, staticRenderFns } from "./ReceiverList.vue?vue&type=template&id=3e8fa191&scoped=true"
import script from "./ReceiverList.vue?vue&type=script&lang=js"
export * from "./ReceiverList.vue?vue&type=script&lang=js"
import style0 from "./ReceiverList.vue?vue&type=style&index=0&id=3e8fa191&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e8fa191",
  null
  
)

export default component.exports